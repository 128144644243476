import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: "AIzaSyArMijVfl9NfrIA4_y-8vTy3MFAYpIFaD4",
  version: "weekly",
  libraries: ["places"]
});

document.addEventListener('DOMContentLoaded', () => {
  /*Menu*/
  var menu = document.querySelectorAll(".event-menu");

  menu.forEach(elm => {
    elm.addEventListener('click', function (e) {
      if(e.target.classList.contains('menu-ancla') || e.target.classList.contains('svg-arrow')){
        e.preventDefault();
        if(this.classList.contains( 'active' )) {
          this.classList.remove('active')
          let list = elm.querySelector('.container-list');
          list.style.cssText = 'height: 0px; box-shadow: 0 0 0 0;';
        } else {
          this.classList.add('active')
          let list = elm.querySelector('.container-list');
          let height = list.scrollHeight + 100;
          list.style.cssText = 'height:'+ height +'px; box-shadow: 0 4px 11px 4px rgb(0 0 0 / 25%);';
        }
      }
    });
  });

    /*Menu MOBILE*/
    var menu = document.getElementsByClassName("event-menu-mobile");

    for (var i = 0; i < menu.length; i++) {
      menu[i].addEventListener('click', function (e) {
        if(e.target.classList.contains('menu-ancla') || e.target.classList.contains('svg-arrow')){
          e.preventDefault();
          if(this.classList.contains( 'active' )) {
            this.classList.remove('active')
            let list = this.lastChild.previousElementSibling;
            list.style.cssText = 'height: 0px';
          } else {
            this.classList.add('active')
            let list = this.lastChild.previousElementSibling;
            let height = list.scrollHeight;
            list.style.cssText = 'height:'+ height +'px';
          }
        }
      });
    }

    var close = document.getElementsByClassName("container-close");

    for (var i = 0; i < menu.length; i++) {
      close[i].addEventListener('click', function (e) {
        e.preventDefault();
        this.parentNode.parentNode.classList.remove('active')
      });
    }

    var open = document.getElementsByClassName("container-burguer");

    for (var i = 0; i < menu.length; i++) {
      open[i].addEventListener('click', function (e) {
        e.preventDefault();
        this.nextSibling.nextElementSibling.classList.add('active')
      });
    }

    /*Scroll down animation in common hero*/
    // var scrollDownAngle = document.getElementById('scroll-down-angle');
    // if(scrollDownAngle){
    //   scrollDownAngle.addEventListener('click', e => {
    //     jQuery('html, body').animate({scrollTop: jQuery('#common-hero').outerHeight() + jQuery('.header-page').outerHeight() }, 'slow');
    //   });
    // }

    var header = document.getElementById("header-page");

    window.onscroll = function() {
      var y = window.scrollY;
      if (y > 100) {
        header.classList.add('active');
      } else {
        header.classList.remove('active');
      }
    };

    /*Map Init in contact section*/

    var maps = document.querySelectorAll('.map-container-api');

    if(maps.length >= 1){

      var mapStyle = [
        {
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#ffffff'
            }
          ]
        },
        {
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#182331'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#29446b'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#182331'
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#375179'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#193a56'
            }
          ]
        },
        {
          'featureType': 'transit.station',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#202c3e'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#182331'
            }
          ]
        }
      ];
      maps.forEach(map => {
        loader
        .load()
        .then((google) => {
          const mapOptions = {
            center: {
              lat: parseFloat(map.dataset.latitude),
              lng: parseFloat(map.dataset.longitude)
            },
            styles: mapStyle,
            zoom: 13
          };
          new google.maps.Map(map, mapOptions);
        })
        .catch(e => {
          // do something
        });
      });
    }

  var shareOptions = document.querySelector('#share-options');
  
  if(shareOptions){
    shareOptions.addEventListener('click', evt => {
      evt.preventDefault();
      document.querySelector('#modal-share-options').classList.add('modal-about-active');
      document.body.style.overflowY = 'hidden';
    });

    var closeButton = document.querySelector('.modal-share-options-close-btn');

    if(closeButton){
      closeButton.addEventListener('click', evt => {
        evt.preventDefault();
        document.querySelector('#modal-share-options').classList.remove('modal-about-active');
        document.body.style.overflowY = 'auto';
      });
    }
  }

});
